import { B2C_ONBOARDING_PAGES } from "core/consts";
import { pathToUrl } from "core/model/utils/urls";
import { B2COnboardingPage } from "core/types";
import { B2CFilters } from "dsl/organisms/Filters/B2CApp/types";
import toFilterQueryString from "dsl/organisms/Filters/utils/toQueryString";
import { Location, NavigateFunction } from "react-router-dom";

export const getB2CNavigationUrls = ({ location }: { location: Location }) => {
  return {
    goToOnboarding({
      page,
      resetFilters,
    }: {
      page?: B2COnboardingPage;
      resetFilters?: boolean;
    }) {
      return pathToUrl({
        pathname: `/b2c/onboarding/${page ?? B2C_ONBOARDING_PAGES.WELCOME}`,
        search: resetFilters ? "" : location.search,
      });
    },
    goToDashboard({ filters }: { filters: B2CFilters }) {
      return pathToUrl({
        pathname: "/b2c/dashboard",
        search: toFilterQueryString(filters),
      });
    },
  };
};

export const getB2CNavigationHandlers = ({
  location,
  navigate,
}: {
  location: Location;
  navigate: NavigateFunction;
}) => {
  const urls = getB2CNavigationUrls({
    location,
  });

  return {
    goToOnboarding(props: {
      page?: B2COnboardingPage;
      resetFilters?: boolean;
    }) {
      navigate(urls.goToOnboarding(props));
    },
    goToDashboard({ filters }: { filters: B2CFilters }) {
      navigate(urls.goToDashboard({ filters }));
    },
  };
};
