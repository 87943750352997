import {
  B2C_ONBOARDING_PAGES,
  SOLUTION_SHORT_TERM_STATIC_CARE,
  SOLUTION_STATIC_CARE,
  TRACK_EVENTS,
} from "core/consts";
import Tile, { TILETYPE } from "ds_legacy/components/TileButton";
import { USER_FILTER_SOLUTIONS } from "dsl/organisms/Filters/consts";
import { AlarmClockIcon, HourglassIcon } from "lucide-react";
import { useTracking } from "react-tracking";
import OnboardingCard, { PageProps, StyledCaption, testIds } from "../common";

export const TERM_CHOICES = [
  SOLUTION_SHORT_TERM_STATIC_CARE,
  SOLUTION_STATIC_CARE,
];

const getTrackingTranslation = (solutions: Array<number>) => {
  const isTemporary = solutions.includes(SOLUTION_SHORT_TERM_STATIC_CARE);
  const isLongterm = solutions.includes(SOLUTION_STATIC_CARE);

  if (isTemporary && isLongterm) {
    return undefined;
  } else if (isTemporary) {
    return "temporary";
  } else if (isLongterm) {
    return "longterm";
  }
};

export default function Term({
  navigateToPage,
  setFilters,
  translations,
}: PageProps) {
  const { trackEvent } = useTracking();

  const onAction = (solutions: Array<number>) => {
    navigateToPage(B2C_ONBOARDING_PAGES.LOCATION);
    trackEvent({
      name: TRACK_EVENTS.DASHBOARD_FILTERS,
      search_target: getTrackingTranslation(solutions),
    });
    setFilters({ type: USER_FILTER_SOLUTIONS, value: solutions });
  };
  return (
    <OnboardingCard
      title={translations.bToC.onboardingScreens.titleTemporaryOrLongterm}
      onNext={() => onAction(TERM_CHOICES)}
      navigateToPage={navigateToPage}
      data-testid={testIds.careTerm}
    >
      <StyledCaption>
        {translations.bToC.onboardingScreens.yourChoice}:
      </StyledCaption>
      <Tile
        label={translations.bToC.onboardingScreens.buttonTemporary}
        Icon={AlarmClockIcon}
        onClick={() => onAction([SOLUTION_SHORT_TERM_STATIC_CARE])}
        tileType={TILETYPE.B2C}
      />
      <Tile
        label={translations.bToC.onboardingScreens.buttonLongterm}
        Icon={HourglassIcon}
        onClick={() => onAction([SOLUTION_STATIC_CARE])}
        tileType={TILETYPE.B2C}
      />
    </OnboardingCard>
  );
}
