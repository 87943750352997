import { B2C_ONBOARDING_PAGES, LOGO_MASTER, TRACK_EVENTS } from "core/consts";
import { RecareNameLogo } from "ds_legacy/components/RecareLogo";
import Tile, { TILETYPE } from "ds_legacy/components/TileButton";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import {
  SMALL,
  margin,
  responsiveCSS,
  sizing,
} from "ds_legacy/materials/metrics";
import { Title } from "ds_legacy/materials/typography";
import Image from "dsl/atoms/Image";
import { useTracking } from "react-tracking";
import styled from "styled-components";
import {
  MOBILE_MAX_WIDTH,
  PAGE_MAX_WIDTH,
  PageHeading,
  PageProps,
  testIds,
} from "../common";

export const WelcomePageWrapper = styled(VerticalLayout)`
  width: 100%;
  max-width: ${PAGE_MAX_WIDTH};
  margin: ${margin(12, 0, 6, 0)};
  align-items: flex-start;
  ${responsiveCSS({
    mobile: [`margin: ${margin(2, 0, 0, 0)}`, `max-width: ${MOBILE_MAX_WIDTH}`],
    tablet: [`margin: ${margin(8, 0, 0, 0)}`],
  })};
`;

const LogosWrapper = styled(HorizontalLayout)`
  width: 100%;
  margin: ${margin(8, 1)};
  ${responsiveCSS({
    mobile: [`justify-content: center`],
    tablet: [`justify-content: center`],
    desktop: [`justify-content: flex-start`],
  })};

  img {
    ${responsiveCSS({
      desktop: [`margin-right: ${sizing(2)}`],
    })};
  }
`;

export default function Welcome({ navigateToPage, translations }: PageProps) {
  const { trackEvent } = useTracking();
  return (
    <div data-testid={testIds.welcome}>
      <WelcomePageWrapper justify="flex-start">
        <VerticalLayout>
          <PageHeading primary bold>
            {translations.bToC.onboardingScreens.titleWelcomeWeserEms}
          </PageHeading>
          <Title margin={margin(2, 1)}>
            {translations.bToC.onboardingScreens.subtitleWelcome}
          </Title>
          <Tile
            label={translations.bToC.getStartedButton}
            onClick={() => {
              trackEvent({
                name: TRACK_EVENTS.B2C_ONBOARDING_LETS_GO_BUTTON_CLICKED,
              });
              navigateToPage(B2C_ONBOARDING_PAGES.TARGET);
            }}
            tileType={TILETYPE.B2C}
          />
        </VerticalLayout>
        <LogosWrapper>
          <Image
            style={{ marginRight: sizing(2) }}
            path="gewinet-logo.png"
            alt="gewinet-logo"
          />
          <RecareNameLogo variant={LOGO_MASTER} size={SMALL} />
        </LogosWrapper>
      </WelcomePageWrapper>
    </div>
  );
}
