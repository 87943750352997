import { B2C_ONBOARDING_PAGES, TRACK_EVENTS } from "core/consts";
import { addDays, addWeeks, getUnixTime } from "date-fns";
import Tile, { TILETYPE } from "ds_legacy/components/TileButton";
import { USER_FILTER_START_DATE } from "dsl/organisms/Filters/consts";
import { AlarmCheckIcon, CalendarDaysIcon, HourglassIcon } from "lucide-react";
import { useTracking } from "react-tracking";
import OnboardingCard, { PageProps, StyledCaption, testIds } from "../common";

export const TODAY = getUnixTime(new Date());
export const THREE_DAYS = getUnixTime(addDays(new Date(), 3));
export const TWO_WEEKS = getUnixTime(addWeeks(new Date(), 2));

const getTrackingTranslation = (date: number) => {
  switch (date) {
    case THREE_DAYS:
      return "in a few days";
    case TWO_WEEKS:
      return "in a few weeks";
    default:
      return "today";
  }
};

export default function StartDate({
  navigateToPage,
  setFilters,
  translations,
}: PageProps) {
  const { trackEvent } = useTracking();

  const onAction = (date: number, skipped?: boolean) => {
    navigateToPage(B2C_ONBOARDING_PAGES.CARE_TYPE);
    trackEvent({
      name: TRACK_EVENTS.DASHBOARD_FILTERS,
      search_target: skipped ? undefined : getTrackingTranslation(date),
    });
    setFilters({ type: USER_FILTER_START_DATE, value: date });
  };
  return (
    <OnboardingCard
      title={translations.bToC.onboardingScreens.titleWhenStartDate}
      onNext={() => onAction(TODAY, true)}
      navigateToPage={navigateToPage}
      data-testid={testIds.startdate}
    >
      <StyledCaption>
        {translations.bToC.onboardingScreens.yourChoice}:
      </StyledCaption>
      <Tile
        label={translations.bToC.onboardingScreens.buttonAsap}
        onClick={() => onAction(TODAY)}
        tileType={TILETYPE.B2C}
        Icon={AlarmCheckIcon}
      />
      <Tile
        label={translations.bToC.onboardingScreens.buttonInDays}
        onClick={() => onAction(THREE_DAYS)}
        tileType={TILETYPE.B2C}
        Icon={HourglassIcon}
      />
      <Tile
        label={translations.bToC.onboardingScreens.buttonInWeeks}
        onClick={() => onAction(TWO_WEEKS)}
        tileType={TILETYPE.B2C}
        Icon={CalendarDaysIcon}
      />
    </OnboardingCard>
  );
}
